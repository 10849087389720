$base_bold_color: #3E4ED8;

@font-face { font-family: Roboto_Regular; src: url('/public/fonts/Roboto/Roboto-Regular.ttf'); } 
@font-face { font-family: Spline_light; src: url('/public/fonts/Spline/SplineSans-Light.ttf'); } 
@font-face { font-family: Spline_SemiBold; src: url('/public/fonts/Spline/SplineSans-SemiBold.ttf'); } 
@font-face { font-family: Spline_Bold; src: url('/public/fonts/Spline/SplineSans-Bold.ttf'); } 


// PC or Notebook

.Header{
    max-width: 100%;
    overflow-x: hidden;

    z-index: 20;
    display: flex;
    position: fixed;
    flex-direction: column;
    background-image: url("/public/img/wave.png");
    background-size: 110%;
    background-position-y: 130%;
    background-position-x: 0%;
    background-repeat: no-repeat
    ;

    width: 30rem;
    border: 1px solid black;
    font-family: Roboto_Regular, Arial, Helvetica, sans-serif;
    font-size: 18px;
    color: #707070;
    height: 100%;
    top: 0;
    left: 0;

    .link_cast{
        text-decoration: none;
        color: #707070;
        margin-top: 2rem;
        hr{
            display: none;
        }
    }

    .link_cast:hover{
        hr{
            display: block;
            color: black;
            width: 70%;
        }
    }

    .logo_section{
        display: flex;
        flex-direction: row;
        width: 100%;
        overflow-y: hidden;
        .logo_image{
            background-image: url("/public/img/logo.jpg");
            width: 17rem;
            height: 8rem;
            margin-left: 1rem;
            background-repeat: no-repeat;
            background-size: contain;
        }
        .language_changer{
            width: 3rem;
            height: 1.5rem;
            margin-top: 1.6rem;
            margin-left: 5rem;
            overflow: hidden;
        }
    }

    ul.menu_list{
        width: 100%;
        list-style: none;
        margin-left: 3rem;
        height: 25rem;

            .menu_item {
                margin-top: 1.5rem;
                width: 15rem;
                height: 2rem;
            }
        }

    .contact_lan_section{
        display: flex;
        position: fixed;
        top: 80%;
        left: 2%;


        select{
            font-family: Roboto_Regular, Arial, Helvetica, sans-serif;
            background-color: rgb(141, 138, 228);
            border-radius: 10px;
            color: rgb(255, 255, 255);
            width: 10rem;
            height: 2rem;
            margin-top: 1.5rem;
            option{
                padding-left: 5rem;
            }

        }
        .contact_button {
            margin-left: 6rem;
        }
    
        .contact_button:hover {
            color: rgb(61, 67, 119);
        }
    }

}

.wrapper {
    display: none;
}

.Header_mobile_top_bar {
    display: none;
}

@media only screen and (max-width: 1440px) { 
    .Header{
        width: 25rem;

        .logo_section{

            .language_changer{ 
                margin-left: 0.5rem;
            }
        }
    }

    
}


// Mobile Menu for tablets 

@media only screen and (max-width: 1080px) { 
    .header_menu {
        width: 100%;
        height: 4rem;
        flex-direction: column;
        position: fixed;
        z-index: 100;
    }
    .Header {
        display: none;
    }

    .Header_mobile_top_bar{
        display: block;
        position: fixed;
        top: 0px;
        left: 0px;
        height: 8%;
        width: 100%;
        background-color: rgba($color: #5963e8, $alpha: 0.7);
        .button_open_menu{
            width: 5rem;
            height: auto;
            margin-left: 20px;
            margin-top: 1rem;
            transition: 0.25s all ease-in-out;
            opacity: 1;
            transform: translate3d(0, 0, 0);
            hr{
                width: 2.5rem;
                height: 0.1rem;
                color: rgb(6, 63, 104);
                background-color: rgb(6, 63, 104);
                border-color: rgb(6, 63, 104);;
                margin-bottom: 10px;
                
            }
            .middle{
                width: 1.5rem;          
            }
        }

        .close {
            transform: translate3d(-30%, 0, 0); 
            opacity: 0;
        }
    }

    .wrapper, .Header_mobile {
        display: flex;
        position: fixed;
        top: 8%;
        overflow-y: hidden;
        left: 0px;
        width: 100%;
        height: 100%;

    }

    .Header_mobile_side {
        width: 40%;
        background-color: rgba($color: #5963e8, $alpha: 1);
        opacity: 1;
        transform: translate3d(0, 0, 0); 
        transition: 0.25s all ease-in-out;
        -ms-overflow-style: none; /* for Internet Explorer, Edge */
        scrollbar-width: none; /* for Firefox */
        overflow-y: scroll; 
        .menu_list {

            overflow-y: hidden; 
            height: 130%;
            margin-top: 5rem;
            display: flex;
            flex-direction: column;
            list-style: none;
            margin-left: 1.5rem;
            font-family: Spline_light, Helvetica, sans-serif;
            .menu_link {
                margin-top: 2rem;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 15rem;
                height: 3.3rem;
                background-color: white;
                border-radius: 20px;
                font-size: 35px;
                color: black;
                text-decoration: none; 
                .text{
                    text-align: center;
                    width: 14rem;
                    font-size: 1.5rem;
                }            
            }
            .menu_link:hover {
                background-color: grey;
                color: white;

            }

            .link_cast:link {
                text-decoration: none;
            }

            select{
                background-color: rgb(141, 138, 228);
                font-family: Roboto_Regular, Arial, Helvetica, sans-serif;
                border-radius: 10px;
                color: rgb(255, 255, 255);
                width: 15rem;
                font-size: 1.5rem;
                height: 3rem;
                margin-top: 1.5rem;
                option{
                    padding-left: 5rem;
                }
            }

        }
        .button_open_menu{
            background-image: url("/public/img/Navigation.png");
            width: 3rem;
            height: 3rem;
            margin-top: 2rem;
            margin-left: 1rem;
            background-repeat: no-repeat;
            border: none;
            .menu_list{
                display: none;
            }

        }

        .button_open_menu:active{
            color: red;
        }
    }

    .Header_mobile_side::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
      }

    .disactive {
        opacity: 0;
        margin-left: -1000px;
        transform: translate3d(-30%, 0, 10); 
    }
}

// Mobile Menu for phone

@media only screen and (max-width: 500px) { 


    .Header_mobile_side {
        width: 60%;
        .menu_list {
            margin-top: 1rem;
            margin-left: 2rem;
            .menu_link {
                width: 10rem;
                .text{
                    font-size: 1rem;
                }            
            }

            select{
                width: 10rem;
                font-size: 1.5rem;
                height: 3rem;
                margin-top: 1.5rem;
                option{
                    padding-left: 5rem;
                }
            }

        }
    }
}

// Extra small mobile menu

@media only screen and (max-width: 340px) {
    .Header_mobile_side {
        .menu_list {
            margin-left: 1rem;
        }
    }
}
