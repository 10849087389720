.main_area {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin-left: 25%;

    height: 100%;
    width: 74%;
    overflow-x: hidden;
    .loader{
        position: fixed;
        top: 50%;
        left: 60%;
        border: 16px solid #f3f3f3; /* Light grey */
        border-top: 16px solid #3498db; /* Blue */
        border-radius: 50%;
        width: 120px;
        height: 120px;
        animation: spin 0.5s linear infinite;
    }

    .child {
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        padding-right: 17px;
        /* Increase/decrease this value for cross-browser compatibility */
        box-sizing: content-box;
        overflow-x: hidden;

        .chage_prop {
            .left {
                width: 30%;
            }

            .right {
                width: 70%;

            }
        }
    }

}

// Desctope 

@media only screen and (max-width: 1680px) {
    .main_area {
        margin-left: 28.8%;
        font-size: 90%;
        width: 69%;
        margin-right: 0px;

        .loader{
            top: 50%;
            left: 40%;
        }
    }
}

@media only screen and (max-width: 1600px) {
    .main_area {
        margin-left: 30%;
        font-size: 90%;
        width: 69%;
        margin-right: 0px;

        .loader{
            top: 50%;
            left: 40%;
        }
    }
}



@media only screen and (max-width: 1536px) {
    .main_area {
        margin-left: 31.5%;
        font-size: 90%;
        .loader{
            top: 50%;
            left: 50%;
        }
    }
}

@media only screen and (max-width: 1440px) {
    .main_area {
        width: 75%;
        margin-left: 28%;
    }
}

@media only screen and (max-width: 1366px) {
    .main_area {
        width: 70%;
        margin-left: 30%;
    }
}



@media only screen and (max-width: 1280px) {
    .main_area {
        width: 70%;
        margin-left: 32%;
    }
}

// Tablet 

@media only screen and (max-width: 1080px) {
    .main_area {
        width: 100%;
        margin-left: 0px;
        padding-top: 4rem;
        .loader{
            top: 50%;
            left: 40%;
        }
    }
}

@media only screen and (max-width: 500px) {
    .main_area {

        .loader{
            top: 50%;
            left: 30%;
        }
    }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
 
  